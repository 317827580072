import { Component, OnInit, EventEmitter, Output, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Subject, Subscription } from 'rxjs';
import { JoWebuserRolesVm } from 'src/app/shared/services/keyvaluepair.servce';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() sidenavToggle = new EventEmitter<void>();
  private appSubscription = new  Subscription();
  private roleSubscription : Subscription;
  private jowebuserRoles : JoWebuserRolesVm[] = [];
  isAuthenticated = false;
  isAdmintype = false;
  isCrew = false;
  isLineMan = false;
  isJoAdmin = false;
  isAdmin = false;
  isContractor = false;

  isModule = false;

  isJoReassign = false;
  isJoReassignToCrew = false;
  isJoReassignToCrewApplyMl = false;
  isJoRelease = false;
  isUserRights = false;
  isNewUser =false;
  isViewUsers = false;


  isSystemRole = false;
  isRolemodule =false;
  isUserModule = false;
  isReports = false;
  isMaterialUsageReport = false;
  isViewJoDetail = false;
  isViewMaps = false;
  isViewNaps = false;
  
  isJoClose = false;
  isJoChangeNnP = false;
  isJoRemarks = false;
  isJoReSchedule = true;
  isActivateCpe = false;
  isTerminateOnt = false;
  isAssignCpe = false;
  isServiceCall = false;
  isOtrsViewer = false;
  isNapLocAssignment = false;
  isRefershCpe = false;
  isBulkTerminate = false;
  currentUser = "";
  title = "Converge ICT";
  defaultCompany = "COMCL";
  userActivity;
  userInactive: Subject<any> = new Subject();
  //constructor( private location: Location, private auth: AuthService, private headerService: HeaderService) { }
  constructor( private location: Location, private auth: AuthService) { }

  ngOnInit(): void {
    this.appSubscription.add(this.auth.authChange.subscribe(authStatus => {
      this.isAuthenticated = authStatus;
      this.isAdmintype = this.auth.getUserType();
    }));
    this.appSubscription.add(this.auth.companyName.subscribe( companyTitle => {
      this.title =  companyTitle;
    }));
    this.appSubscription.add(this.auth.currentUser.subscribe( currentUser => {
      this.currentUser =  currentUser.toLowerCase();
    }));
    this.roleSubscription = this.auth.receivedJoWebuserRolesVm().subscribe( roles => {
      this.jowebuserRoles = roles;
    });
    this.setTimeout();
        this.appSubscription.add(this.userInactive.subscribe(() => {
        this.onLogout();
    }));  

    this.isAuthenticated = true;
    
  }
  ngAfterViewInit() {
    this.refreshSubscription();
    this.changeTitle(this.title);
  }
  private refreshSubscription =  () => {
    this.appSubscription.add(this.auth.isCrew.subscribe(res => {this.isCrew = res;}));
    this.appSubscription.add(this.auth.isLineman.subscribe( res => { this.isLineMan = res}));
    this.appSubscription.add(this.auth.isAdmin.subscribe(res => { this.isAdmin = res;}));
    this.appSubscription.add(this.auth.isJoAdmin.subscribe( res => { this.isJoAdmin = res}));
    this.appSubscription.add(this.auth.isContractor.subscribe(res => { this.isContractor = res}));
    this.appSubscription.add(this.auth.isModule.subscribe(res => { this.isModule = res}));

    this.appSubscription.add(this.auth.receiveJoReassign().subscribe(res => {this.isJoReassign = res; }));
    this.appSubscription.add(this.auth.receiveJoReassignToCrew().subscribe(res => {this.isJoReassignToCrew = res; }));
    this.appSubscription.add(this.auth.receiveJoReassignToCrewApplyMl().subscribe(res => {this.isJoReassignToCrewApplyMl = res; }));
    this.appSubscription.add(this.auth.receiveJoRelease().subscribe( res => { this.isJoRelease = res; }));
    this.appSubscription.add(this.auth.receiveJoClose().subscribe( res => { this.isJoClose = false; }));
    this.appSubscription.add(this.auth.receiveJoChangeNnP().subscribe( res => { this.isJoChangeNnP = res; }));
    this.appSubscription.add(this.auth.receiveJoRemarks().subscribe( res => { this.isJoRemarks = false; }));
    this.appSubscription.add(this.auth.receiveJoReschedule().subscribe( res => { this.isJoReSchedule = false; }));
    this.appSubscription.add(this.auth.receiveActivateCpe().subscribe( res => { this.isActivateCpe = res; }));
    this.appSubscription.add(this.auth.receiveTerminateOnt().subscribe( res => { this.isTerminateOnt = res; }));
    this.appSubscription.add(this.auth.receiveRefreshCpe().subscribe( res => { this.isRefershCpe = res; }));
    this.appSubscription.add(this.auth.receiveAssignCpe().subscribe( res => { this.isAssignCpe = false; }));
    this.appSubscription.add(this.auth.receiveServiceCall().subscribe( res => { this.isServiceCall = false; }));
    this.appSubscription.add(this.auth.receiveNapLocAssignment().subscribe( res => { this.isNapLocAssignment = res; }));
    this.appSubscription.add(this.auth.receiveBulkTerminate().subscribe( res => { this.isBulkTerminate = res; }));

    this.appSubscription.add(this.auth.receiveModule().subscribe(res => { this.isModule = res;}));
    this.appSubscription.add(this.auth.receiveNewUser().subscribe( res => { this.isNewUser = res}));
    this.appSubscription.add(this.auth.receiveReports().subscribe(res => { this.isReports = res}));
    this.appSubscription.add(this.auth.receiveMaterialUsageReport().subscribe(res => { this.isMaterialUsageReport = res}));
    
    this.appSubscription.add(this.auth.receiveRolemodule().subscribe(res => { this.isRolemodule = res}));
    this.appSubscription.add(this.auth.receiveSystemRole().subscribe(res => {this.isSystemRole = res;}));
    this.appSubscription.add(this.auth.receiveUserModule().subscribe( res => { this.isUserModule = res}));
    this.appSubscription.add(this.auth.receiveUserRights().subscribe(res => { this.isUserRights = res;}));

    this.appSubscription.add(this.auth.receiveViewJoDetail().subscribe( res => { this.isViewJoDetail = res}));
    this.appSubscription.add(this.auth.receiveOtrsViewer().subscribe( res => { this.isOtrsViewer = res}));
    this.appSubscription.add(this.auth.receiveNapsViewer().subscribe( res => { this.isViewNaps = res}));
    this.appSubscription.add(this.auth.receiveViewUsers().subscribe(res => { this.isViewUsers = res}));
    this.appSubscription.add(this.auth.receiveDefaultCompanyCode().subscribe(res => { this.defaultCompany = res}));
    
    //this.isUserRights = true;
    //this.isViewUsers = true;
    }
  setTimeout() {
    this.userActivity = setTimeout(() => {
      if (this.auth.isAuth) {
        this.userInactive.next(undefined);
      }
    }, 900000);
  }
  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  onToggleSidenav() {
    this.sidenavToggle.emit();
  }
  ngOnDestroy() {
    this.appSubscription.unsubscribe();
    this.roleSubscription.unsubscribe();
  }
  onBack() {
    this.location.back();
  }
  onLogout() {
    this.title = "Converge ICT";
    this.auth.logOut();
    this.jowebuserRoles = [];
  }
  changeTitle(title) {
    this.title = title;
  }
  changeDefaultRecordToRetrieve(noOfRecord = '1000') {
    this.auth.defaultRetrieveRecord = noOfRecord;
  }
  //withAccessRight(data): boolean {
    //return this.headerService.validateModule(data)
  //}
}