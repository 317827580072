import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeyValuePair } from 'src/app/shared/interfaces/keyvaluepair.interface';
import { environment } from '../../../environments/environment'
import { FullAccountNo } from '../interfaces/fullaccountno.interface';


@Injectable({
  providedIn: 'root'
})
export class KeyValuePairService {
  private purpUrl = environment.apiUrl ;
  constructor(private http: HttpClient) {}
  getKeyValuePair(payload): Observable<KeyValuePair[]> {
    return this.http.post<KeyValuePair[]>(this.purpUrl+'lookup',payload );
  };
  getAccountNumber(payload: AccountNumber): Observable<FullAccountNo> {
    
    return this.http.post<FullAccountNo>(this.purpUrl+'fullaccount',payload );
  };

  getUserByEmailAddress(payload: string): Observable<JoMobileUser> {
        return this.http.get<JoMobileUser>(this.purpUrl+'jomobileuser?id='+payload);
  };

  getUserByEmailAddressWithDecrypt(payload: string): Observable<JoMobileUserNew> {
    return this.http.get<JoMobileUserNew>(this.purpUrl+'jomobileuser/searchbyemail?id='+payload);
};
  updateUserByEmailAddress(payload: JoMobileUser): Observable<JoMobileUser> {

    return this.http.post<JoMobileUser>(this.purpUrl+'jomobileuser/updatejouser',payload );
  };

  amendUserByEmailAddress(payload: JoMobileUserNew): Observable<JoMobileUserNew> {
    return this.http.post<JoMobileUserNew>(this.purpUrl+'jomobileuser/amenduser',payload );
  };

  changePasswordByEmailAddress(payload: ResetPassword): Observable<any> {
    return this.http.post<ChangePassword>(this.purpUrl+'jomobileuser/ChangePassword',payload );
  };
  changeConditinalClosingByEmailAddress(payload: ChangeConditional): Observable<any> {
    return this.http.post<ChangeConditional>(this.purpUrl+'jomobileuser/ChangeConditional',payload );
  };
  addNewJoMobileUser(payload: JoMobileUser): Observable<JoMobileUser> {
    return this.http.post<JoMobileUser>(this.purpUrl+'jomobileuser',payload );
  };
  addNewJoMobileUsernew(payload: JoMobileUserNew): Observable<JoMobileUserNew> {
    return this.http.post<JoMobileUserNew>(this.purpUrl+'jomobileuser/createnewuser',payload );
  };
  registerLineman(payload: Lineman): Observable<Lineman> {
    return this.http.post<Lineman>(this.purpUrl+'linemanmaster',payload );
  };
  registerJoMobileUser(payload: JoMobileUser): Observable<JoMobileUser> {
    return this.http.post<JoMobileUser>(this.purpUrl+'jomobileUser',payload );
  };

}
export interface AccountNumber {
  acctNo: string;
  companyCode: string;
  divisionCode: string;
}

export interface JoMobileUser
{
    emailAddress: string;
    linemanCode : string;
    fullName: string;
    companyCode: string;
    divisionCode: string;
    joMobileUserType: string;
    password: string;
    id: string;
    divisions: string;
    provinces: string;
    municipalities: string;
      
}
export interface JoMobileUserNew
{
    emailAddress: string;
    linemanCode : string;
    fullName: string;
    companyCode: string;
    divisionCode: string;
    joMobileUserType: string;
    password: string;
    id: string;
    divisions: string;
    provinces: string;
    municipalities: string;
    provinceMunicipality: ProvinceMunicipality[];  
    tranTypeCodes: string[];
    locationCodes: string[];
}

export interface Lineman {
  linemanCode: string;
  linemanName: string;
  emailAddress: string;
  companyCode: string;
  divisionCode: string;
}
export interface ProvinceMunicipality {
  provinceCode: string;
  municipalityCode: string;
}

export interface JoWebuserRolesVm {
  moduleAccess: string;
  moduleName: string;
  emailAddress: string;
}
export interface ChangePassword {
  emailAddress: string;
  password: string;
}
export interface ChangeConditional {
  emailAddress: string;
  isConditional: string;
}

export interface ResetPassword {
  password: string;
}